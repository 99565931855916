import React, { useRef, useState } from 'react';
import "./CareerPage.scss";
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { InputMask } from 'primereact/inputmask';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import Header from '../../Header/Header';

const CareerPage = () => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        location: '',
        position: '',
        experience: '',
        base64Data: '',
    });

    const [pdfData, setPdfData] = useState('');
    const [errorData, setErrorData] = useState('');

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const fileUploadRef = useRef(null);

    const onSelect = (event) => {
        const file = event.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setPdfData(reader.result);
            setErrorData('');
        };
        reader.readAsDataURL(file);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!pdfData) {
            setErrorData('Please Upload Your Resume.')
            // alert('Please Upload Your Resume.');
            return;
        }

        setErrorData('');

        const payload = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
            location: formData.location,
            position: formData.position,
            experience: formData.experience,
            base64Data: pdfData,
            date: new Date().toISOString()
        };

        try {
            const response = await fetch('https://f542exmzb8.execute-api.us-east-1.amazonaws.com/sendEmail', {
                method: 'POST',
                mode: 'no-cors',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {

                setShowSuccessMessage(true);

                setTimeout(() => {
                    setShowSuccessMessage(false);
                    if (fileUploadRef.current) {
                        fileUploadRef.current.clear();
                    }
                    setFormData({
                        firstName: '',
                        lastName: '',
                        email: '',
                        phoneNumber: '',
                        location: '',
                        position: '',
                        experience: '',
                        base64Data: '',
                    })
                    setErrorData('');
                    setPdfData('');
                }, 3000);
            }

            if (!response.ok) {
                const errorText = await response.text();
                console.error("HTTP Error: ", response.status, errorText);
                throw new Error(`HTTP Error: ${response.status} ${errorText}`);
            }
        } catch (error) {
            console.error("Error during fetch: ", error);
        }
    };


    return (
        <>
            <Header />

            <div className="career-page">
                <div className="career-page-text">
                    <h2>JOIN OUR PACK<span>.</span></h2>
                    <p>We help organizations across the globe achieve digital transformation.</p>
                </div>

                <div className="career-page-form">
                    <div className="contactus-section-banner-form">
                        <div className="contactus-form">
                            <form onSubmit={handleSubmit}>
                                <div className="field col">
                                    <label>First Name</label>
                                    <InputText
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleChange}
                                        className="p-inputtext-sm w-full"
                                        required
                                    />
                                </div>
                                <div className="field col">
                                    <label>Last Name</label>
                                    <InputText
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleChange}
                                        className="p-inputtext-sm w-full"
                                    />
                                </div>
                                <div className="field col">
                                    <label>Email</label>
                                    <InputText
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        className="p-inputtext-sm w-full"
                                        required
                                    />
                                </div>
                                <div className="flex col w-max">
                                    <div className="field col-6 pl-0">
                                        <label>Phone Number</label>
                                        <InputMask
                                            name="phoneNumber"
                                            value={formData.phoneNumber}
                                            onChange={handleChange}
                                            className="p-inputtext-sm w-full"
                                            mask="9999999999"
                                            required
                                        >
                                        </InputMask>
                                    </div>
                                    <div className="field col-6 pr-0">
                                        <label>Current Location</label>
                                        <InputText
                                            type="text"
                                            name="location"
                                            value={formData.location}
                                            onChange={handleChange}
                                            className="p-inputtext-sm w-full"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="field col">
                                    <label>Applying For</label>
                                    <InputText
                                        type="text"
                                        name="position"
                                        value={formData.position}
                                        onChange={handleChange}
                                        className="p-inputtext-sm w-full"
                                        required
                                    />
                                </div>
                                <div className="field col">
                                    <label>Total Experience (In Years)</label>
                                    <InputText
                                        type="number"
                                        name="experience"
                                        value={formData.experience}
                                        onChange={handleChange}
                                        className="p-inputtext-sm w-full"
                                        required
                                    />
                                </div>

                                <div className="submit-btn">
                                    <FileUpload
                                        ref={fileUploadRef}
                                        mode="basic"
                                        name="demo[]"
                                        accept=".pdf,.doc,.docx"
                                        maxFileSize={1000000}
                                        onSelect={onSelect}
                                        chooseLabel="Upload Your Resume"
                                        value={formData.base64Data}
                                        required
                                    />

                                    <Button className="submit-button" type="submit">Submit</Button>

                                </div>
                                <div className="grid justify-content-center">
                                    {errorData && <p className="error-msg">{errorData}</p>}
                                </div>

                                {showSuccessMessage && (
                                    <div className="success-messages">
                                        <div className="success-message-contents">
                                            <div className="success-message-headings">Submitted Successfully !</div>
                                            <AiOutlineCheckCircle className='check-icon'></AiOutlineCheckCircle>
                                        </div>
                                    </div>
                                )}

                            </form>
                        </div>
                        <div className="career-page-form-content">
                            <p>Beyond jobs, we open the doors for your growth
                                <span>.</span> &nbsp;
                                <span>Are you up for the Journey?</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CareerPage