
import React from 'react';
import Main from "../src/HomePage/Main";
import About from "../src/AboutPage/About";
import Product from '../src/ProductPage/Product';
import Footer from "../src/FooterPage/Footer";
// import Navbar from '../src/NavPage/Nav';
import Contact from "../src/ReachUs/Contact";
import ContactUs from "../src/ReachUs/ContactUs";
import Careers from "./ReachUs/CareersSection";
import Blog from '../src/BlogPage/Blog';
import CustomCursor from './CustomCursor/CustomCursor';
import Services from "./ServicePage/Services";
import Forbidden from "./ForbiddenPage/Forbidden";
import './App.css';
import { BrowserRouter, Route, Routes, useLocation, Navigate } from "react-router-dom";
import SinglePost from './BlogPage/single-post/SinglePost';
import ReactGA from 'react-ga';
import Brand from '../src/Brand/Brand';
import CareerPage from './ReachUs/CareerPage/CareerPage';
import FooterPage from './FooterPage/FooterPage';
import BlogView from './BlogPage/BlogView/BlogView';
import ProductPage from './WhatWeDo/ProductPage';
import WhoWeAre from './WhoWeAre/WhoWeAre';



const TRACKING_ID = "UA-276699714-1"; //our tracking id
ReactGA.initialize(TRACKING_ID);

export default function App() {
  window.scrollTo(0,0);
  return (
    <div>
    <BrowserRouter>
    
      {/* <Navbar /> */}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/about" element={<About />} />
        <Route path="/product" element={<Product />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/careers" element={<CareerPage />} />
        <Route path="/service" element={<Services />} />
        <Route path="/blog" element={<Blog />} />
        {/* <Route path="/blog/:id" element={<SinglePost />} /> */}
        <Route path="/blog/:id" element={<BlogView />} />
        <Route path="/whoWeAre" element={<WhoWeAre />} />
        <Route path="/whatWeDo/:id" element={<ProductPage />} />

        <Route path="/*" element={<ForbiddenWrapper />} />

      </Routes>

      <FooterPage/>
      
    </BrowserRouter>
    {/* <CustomCursor/> */}
    </div>
  )
}

function FooterWrapper() {
  const location = useLocation();
  const isForbiddenPage = location.pathname.startsWith('/forbidden');

  if (isForbiddenPage || location.pathname === '/*') {
    return null;
  }

  return <Footer />;
}

function ForbiddenWrapper() {
  const location = useLocation();
  const isForbiddenPage = location.pathname.startsWith('/forbidden');

  if (isForbiddenPage) {
    return <Forbidden />;
  }

  return <Navigate to="/forbidden" />; 
}