import React, { useState } from 'react';
import "./ContactUs.scss";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import Navbar from '../NavPage/Nav';
import usa from "../Assets/ContactUs/USA.jpg";
import india from "../Assets/ContactUs/India.jpg";
import FooterPage from '../FooterPage/FooterPage';
import axios from 'axios';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import Header from '../Header/Header';

const ContactUs = () => {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        organization: '',
        region: '',
        message: ''
    });

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const apiURL = process.env.REACT_APP_CONTACTUS_HOST;

        const payload = {
            message: {
                fullName: formData.firstName + ' ' + formData.lastName,
                email: formData.email,
                organization: formData.organization,
                region: formData.region,
                message: formData.message
            }
        };

        if (formData.firstName && formData.organization && formData.message && formData.email && formData.region) {

            axios
                .post(apiURL, payload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    setShowSuccessMessage(true);

                    setTimeout(() => {
                        setShowSuccessMessage(false);
                        setFormData({
                            firstName: '',
                            lastName: '',
                            email: '',
                            organization: '',
                            region: '',
                            message: ''
                        })
                    }, 3000);
                })
        }
    };

    return (
        <>
            {/* <Navbar /> */}

            <Header />

            <>
                <div className="contactus-section-banner">
                    <div className="contactus-section-overlay">
                        <div className="contactus-section-banner-content-section">
                            <div className="contactus-section-banner-content">
                                <h2>REQUEST FOR SERVICE<span>.</span></h2>
                                <p>
                                    Our unique solutions create transformation across every industry,
                                    and we're excited to make solutions that best suit your business.
                                    Tell us a little of yourself, and let's set things in motion.
                                </p>
                            </div>

                            <div className="contactus-section-banner-form">
                                <div className="contactus-form">
                                    <h2>Let's Talk</h2>
                                    <form onSubmit={handleSubmit}>
                                        <div className="field col">
                                            <label>First Name</label>
                                            <InputText
                                                type="text"
                                                name="firstName"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                                className="p-inputtext-sm w-full"
                                                required
                                            />
                                        </div>
                                        <div className="field col">
                                            <label>Last Name</label>
                                            <InputText
                                                type="text"
                                                name="lastName"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                                className="p-inputtext-sm w-full"
                                            />
                                        </div>
                                        <div className="field col">
                                            <label>Email</label>
                                            <InputText
                                                type="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                className="p-inputtext-sm w-full"
                                                required
                                            />
                                        </div>
                                        <div className="flex col w-max">
                                            <div className="field col-6 pl-0">
                                                <label>Organization</label>
                                                <InputText
                                                    type="text"
                                                    name="organization"
                                                    value={formData.organization}
                                                    onChange={handleChange}
                                                    className="p-inputtext-sm w-full"
                                                    required
                                                />
                                            </div>
                                            <div className="field col-6 pr-0">
                                                <label>Region</label>
                                                <InputText
                                                    type="text"
                                                    name="region"
                                                    value={formData.region}
                                                    onChange={handleChange}
                                                    className="p-inputtext-sm w-full"
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="field col">
                                            <label>How Can We Help You...</label>
                                            <InputTextarea
                                                name="message"
                                                value={formData.message}
                                                onChange={handleChange}
                                                className="p-inputtext-sm w-full"
                                                required
                                            />
                                        </div>
                                        <div className="submit-btn">
                                            <button type="submit">Submit</button>
                                        </div>

                                        {showSuccessMessage && (
                                            <div className="success-messages">
                                                <div className="success-message-contents">
                                                    <div className="success-message-headings">Submitted Successfully !</div>
                                                    <AiOutlineCheckCircle className='check-icon'></AiOutlineCheckCircle>
                                                </div>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-us-section-sales">
                    <div className="contact-us-section-sales-overlay">
                        <div className="contact-us-section-sales-content">
                            <h2>Interested In Our Product?</h2>
                            <p className='contact-us-section-para'>
                                Talk to our sales team.
                            </p>
                            <p className='contact-us-section-para'>
                                <a href="mailto:sales@althisolutions.com" target="_blank" rel="noopener noreferrer">sales@althisolutions.com</a>
                            </p>
                            <p className='contact-us-section-para'>
                                From questions about pricing to personalized
                                one-on-one demos, we’re here to help you get started.
                            </p>
                        </div>
                        <div className="contact-us-section-sales-images">
                            <article class="card location-cardd">
                                <img
                                    class="card__background"
                                    src={usa}
                                    alt="USA"
                                    width="300"
                                    height="350"
                                />
                                <div class="card__content | flow">
                                    <div class="card__content--container | flow">
                                        <div className="tag">USA</div>
                                        <div className="card__title">
                                            <p>1200 ford rd,</p>
                                            <p>Farmers Branch,</p>
                                            <p>Texas - 75234.</p>
                                        </div>
                                    </div>
                                </div>
                            </article>

                            <article class="card location-cardds">
                                <div className="tag">India</div>
                                <img
                                    class="card__background"
                                    src={india}
                                    alt="India"
                                    width="300"
                                />
                                <div class="card__contents ">
                                    <div class="card__content--container">
                                        <div className="card__title">
                                            <p>6/483 C,Fathima Road East,</p>
                                            <p>Azhagappapuram,</p>
                                            <p>Kanyakumari District,</p>
                                            <p>Tamil Nadu - 629401.</p>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>

                {/* <FooterPage /> */}
            </>
        </>
    );
};

export default ContactUs;
