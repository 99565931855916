import React from 'react';

import "../HomePage/Banner.css";

import bannerVideo from "../Media/new_bg_vdo.mp4";

import VideoPlayer from "react-background-video-player";

// import monitor from '../Media/monitorround.png';

export default function AboutBanner() {

    return (





        <>

<div className='home-banner'>

<VideoPlayer

                className="vdoban-bg"

                src={bannerVideo}

                autoPlay={true}

                muted={true}

              />

    <div className='banner-bg'>

    <div className='container'>




<div className='banner-head-m'>

<div className='banner-hd typewriter'><h3>Building Software with <span className='trus'>Trust.</span></h3>
<p className='small'>Operational Efficiency as the key priority</p>    
</div>



{/* <div className='banner-img'><img src={monitor} alt='round' className='imgban-img'/></div> */}

</div>





    </div>

    {/* <div className="custom-shape-divider-bottom-1685695278">

<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">

<path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>

</svg>

</div> */}
{/* <div className="custom-shape-divider-bottom-1686309061">
<svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
<path d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z" className="shape-fill"></path>
</svg>
</div> */}

</div>




</div>

        </>

    );

}