import React, { Component } from 'react';
import "../ReachUs/Careers.css";
import Navbar from '../NavPage/Nav'
import { eventTrack } from "../google/analytics";
// import careerimg from "../Media/careerimg.png";
import { MdCloudUpload } from 'react-icons/md';
import { AiOutlineCheckCircle } from 'react-icons/ai'




class Careers extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      fileName: '',
      fullname: "",
      email: "",
      contactno: "",
      errorMessage: "",
      successMessage: false,
      name: "",
      jobrole: "",
      base64Data: "",
    };

    this.fileInputRef = React.createRef();
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clickEventHandler = this.clickEventHandler.bind(this);
  }



  handleChange(event) {

    switch (event.target.name) {

      case "fullname":

        this.setState({
          fullname: event.target.value,
        });

        break;

      case "email":

        this.setState({
          email: event.target.value,
        });

        break;

      case "contactno":

        this.setState({
          contactno: event.target.value,
        });

        break;

      case "jobrole":

        this.setState({
          jobrole: event.target.value,
        });

        break;


      default:

        break;

    }


  }

  handleFileSelect = (event) => {
    const file = event.target.files[0];
    this.convertBase64(file)
      .then((base64) => {
        this.setState({
          selectedFile: file,
          fileName: file.name,
          base64Data: base64, // Add this line to store the base64 data in state
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };


  handleUploadButtonClick = () => {
    this.fileInputRef.current.click();
  };

  handleSubmit(event) {
    event.preventDefault();
    const fileName = this.state.fileName;
    const fullName = this.state.fullname;
    const email = this.state.email;
    const contactno = this.state.contactno;
    const jobrole = this.state.jobrole;
    const base64Data = this.state.base64Data;

    const apiURL = process.env.REACT_APP_CAREER_HOST;

    const apiPayload = {
      fileName: fileName,
      senderName: fullName,
      phoneNumber: contactno,
      senderEmail: email,
      jobRole: jobrole,
      base64Data: base64Data,
    };

    console.log(JSON.stringify(JSON.stringify(apiPayload)));

    if (email && contactno && jobrole && fullName) {
      fetch(apiURL, {
        // mode: "no-cors",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ body: JSON.stringify(apiPayload) }),
      })

        .then((response) => {
          console.log(response);
          if (response.ok) {
            setTimeout(() => {
              this.setState({ showSuccessMessage: false });
            }, 3000);

            this.setState({
              fullname: "",
              email: "",
              contactno: "",
              jobrole: "",
              showSuccessMessage: true,
            });

          } else {
            throw new Error("Network response was not ok");
          }

        })
        .catch((error) => {
          console.log(error);
          alert("Sorry, there is a problem. Please try again later.");
        });

    } else {

      alert("Please enter the values.");

    }

  }

  maxLengthCheck = (object) => {




    if (object.target.value.length > object.target.maxLength) {




      object.target.value = object.target.value.slice(0, object.target.maxLength)




    }




  }




  clickEventHandler(event) {




    eventTrack(




      event.target.innerText,




      event.type,




      event.view.navigator.appVersion




    );




  }



  render() {

    const { fileName, showSuccessMessage } = this.state;

    const buttonText = fileName ? fileName : 'Upload Your Resume';




    return (
      <>
        <Navbar />
        <div className='career-main'>

          <div className='career-wrapper'>

            {/* <img className='imagecare' src={careerimg} alt="career-logo" /> */}

            <div className='carrer-screen'>
              {/* 
          <div className='container'>

          <div className='career-mainhead'>

          <div>Let's build something great <span className='togeth'>together</span></div>

      </div>

      </div> */}

              <div className='career-wave'>



                {/* <svg width="100%" height="490" viewBox="0 0 1920 490" fill="none" xmlns="http://www.w3.org/2000/svg">

<path d="M600.5 168.514C238.9 -43.8862 26.8333 -11.3196 -34 31.5138V483.514H1955V277.514C1803.4 364.714 1522.5 397.514 1401 403.014C1092.2 418.214 738.667 253.014 600.5 168.514Z" fill="url(#paint0_linear_62_109)"/>

<path d="M0 490V308.901C176.892 128.802 557.957 205.844 726.378 266.878C1338.3 522.218 1777.09 336.249 1920 211.347V490H0Z" fill="white"/>

<path d="M0.5 316.5V150C183.3 23.9998 591 187.5 772 285C323.5 119.458 62.5 232 0.5 316.5Z" fill="white" fill-opacity="0.22"/>

<defs>

<linearGradient id="paint0_linear_62_109" x1="-103" y1="70.5" x2="1360.5" y2="-0.00010699" gradientUnits="userSpaceOnUse">

<stop offset="0.0899423" stop-color="#75A398"/>

<stop offset="0.365835" stop-color="#75A398"/>

</linearGradient>

</defs>

</svg> */}




              </div>

            </div>

          </div>




          {/* Form section */}

          <div className='career-formsection'>

            <div className='container'>

              <div className='career-form'>

                <div className='form-heading'>

                  <div className="career-heading">

                    Let's Build Together!



                  </div>



                  {/* <div className='career-line'><div className='career-round'></div ></div> */}



                </div>

              </div>





              <div className='career-section'>

                <div className="career-label">Name</div>

                <div className="career-field">



                  <input

                    className="career-input"

                    type={"text"}




                    name={"fullname"}




                    required={true}




                    value={this.state.fullname}




                    onChange={this.handleChange}



                  ></input>

                </div>

                <div className="career-label">Email</div>

                <div className="career-field">



                  <input

                    className="career-input"

                    type={"email"}




                    name={"email"}




                    required={true}




                    value={this.state.email}




                    onChange={this.handleChange}



                  ></input>

                </div>

                <div className="career-label">Phone</div>

                <div className="career-field">



                  <input

                    className="career-input"

                    type="number"




                    maxlength="10"




                    onInput={this.maxLengthCheck}




                    name={"contactno"}




                    required={true}




                    value={this.state.contactno}




                    onChange={this.handleChange}



                  ></input>

                </div>

                <div className="career-label">Job Role</div>

                <div className="career-field">



                  <input

                    className="career-input"

                    type={"text"}

                    name={"jobrole"}




                    required={true}




                    value={this.state.jobrole}




                    onChange={this.handleChange}



                  ></input>

                </div>

                <div>

                </div>

              </div>






              <div>

                <div className='file-upload'>

                  <button className="upload-button" onClick={this.handleUploadButtonClick}>

                    <MdCloudUpload className='upload-icon' />

                    {buttonText}

                  </button>

                  <input

                    className="upload-input"

                    type="file"

                    name="resume"

                    ref={this.fileInputRef}

                    onChange={this.handleFileSelect}

                    accept=".pdf,.doc,.docx,.png"

                    required

                    style={{ display: 'none' }}

                  />

                  {/* <div>{fileName}</div> */}

                </div>



              </div>




              <div className="career-button">

                <button className="career-submit" onClick={this.handleSubmit}>Submit</button>

              </div>

              {showSuccessMessage && (

                <div className="success-message">

                  <div className="success-message-content">

                    <AiOutlineCheckCircle className='checkicon'></AiOutlineCheckCircle>

                    <div className="success-message-heading">Successfully Submitted</div>





                  </div>

                </div>

              )}

            </div>

          </div>

        </div>

      </>

    );

  }

}




export default Careers;