import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import "../ServicePage/Service.css";
import serimg1 from "../Media/serimage.png";
import serimg2 from "../Media/seroimg2.png";
import serimg3 from "../Media/serimg3.png";
import serimg4 from "../Media/serimg4.png";
import serimg5 from "../Media/serimg5.png";
import serimg6 from "../Media/serimg6.png";

export default function Servicecontent() {
  const [activeService, setActiveService] = useState(1); // Initialize activeService with 1
  const [isMobileView, setIsMobileView] = useState(false);

  const handleServiceClick = (serviceNumber) => {
    setActiveService(serviceNumber);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 801); // Adjust the breakpoint as per your requirements
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='servicecontent-wrapper'>
      {!isMobileView && (
        <div className='container'>
           <p className='ser-heading'>Services</p>
          <div className='sercontent'>
            <div className='rightsercontent'>
              {/* <p className='ser-heading'>Services</p> */}
              <div className='content-group'>
                <div
                  className={`sersmallcontent1 ${activeService === 1 ? 'active' : ''}`}
                  onClick={() => handleServiceClick(1)}
                >
                  <p className={`ser-number ${activeService === 1 ? 'active' : ''}`}>01</p>
                  <div className={`vertical-line ${activeService >= 1 ? 'active' : ''}`}></div>
                  <p className={`consult ${activeService === 1 ? 'active' : ''}`}>Consultation services</p>
                </div>

                <div
                className={`sersmallcontent1 ${activeService === 2 ? 'active' : ''}`}
                onClick={() => handleServiceClick(2)}
              >
                <p  className={`ser-number ${activeService === 2 ? 'active' : ''}`}>02</p>
                <p className={`consult ${activeService === 2 ? 'active' : ''}`}>Enterprise software development</p>
              </div>
              <div
                className={`sersmallcontent1 ${activeService === 3 ? 'active' : ''}`}
                onClick={() => handleServiceClick(3)}
              >
                <p  className={`ser-number ${activeService === 3 ? 'active' : ''}`}>03</p>
                <p className={`consult ${activeService === 3 ? 'active' : ''}`}>Web & App development</p>
              </div>
              <div
                className={`sersmallcontent1 ${activeService === 4 ? 'active' : ''}`}
                onClick={() => handleServiceClick(4)}
              >
                <p  className={`ser-number ${activeService === 4 ? 'active' : ''}`}>04</p>
                <p className={`consult ${activeService === 4 ? 'active' : ''}`}>Web design</p>
              </div>
              <div
                className={`sersmallcontent1 ${activeService === 5 ? 'active' : ''}`}
                onClick={() => handleServiceClick(5)}
              >
                <p  className={`ser-number ${activeService === 5 ? 'active' : ''}`}>05</p>
                <p className={`consult ${activeService === 5 ? 'active' : ''}`}>Outsourcing Services</p>
              </div>
              <div
                className={`sersmallcontent1 ${activeService === 6 ? 'active' : ''}`}
                onClick={() => handleServiceClick(6)}
              >
                <p  className={`ser-number ${activeService === 6 ? 'active' : ''}`}>06</p>
                <p className={`consult ${activeService === 6 ? 'active' : ''}`}>Data Mining</p>
              </div>
              </div>
            </div>
            <div className='leftsercontent'>
              {activeService === 1 && (
                <img
                  className='seroimg'
                  src={serimg1}
                  alt='ser'
                ></img>
              )}
                {activeService === 2 && (
              <img
                className='seroimg'
                src={serimg2}
                alt='ser'
              ></img>
            )}
            {activeService === 3 && (
              <img
                className='seroimg'
                src={serimg3}
                alt='ser'
              ></img>
            )}
            {activeService === 4 && (
              <img
                className='seroimg'
                src={serimg4}
                alt='ser'
              ></img>
            )}
            {activeService === 5 && (
              <img
                className='seroimg'
                src={serimg5}
                alt='ser'
              ></img>
            )}
            {activeService === 6 && (
              <img
                className='seroimg'
                src={serimg6}
                alt='ser'
              ></img>
            )}
             {activeService === 1 && (
              <p className='serpro'>
                We provide extensive industry experience and deep technical expertise and offer expert guidance and strategic insights to help businesses navigate the complex landscape of software development and digital transformation. The cost of our services is comparatively cheaper in the sector.
              </p>
            )}
           {activeService === 2 && (
              <p className='serpro'>
                We offer exceptional enterprise software development solutions tailored to meet your organization's specific needs from requirement analysis to seamless integration, trust our expert team to deliver scalable and secure software solutions that drive productivity and propel your business forward.
              </p>
            )}
            {activeService === 3 && (
              <p className='serpro'>
                We are committed to achieving outstanding outcomes for our clients by developing custom applications and enhancing existing software solutions. Our expertise is to deliver tailored solutions that optimize performance and meet unique business requirements in Android, iOS, and all possible platforms.
              </p>
            )}
            {activeService === 4 && (
              <p className='serpro'>
                We specialize in designing UX & UI for a diverse range of web and mobile applications by integrating trends customized to meet clients' unique business goals and requirements.
              </p>
            )}
            {activeService === 5 && (
              <p className='serpro'>
                With an obsession for delivering value and best-in-class customer experience, we provide the best people, processes, and technology.
              </p>
            )}
            {activeService === 6 && (
              <p className='serpro'>
                Our unique and specific data mining techniques will assist companies in addressing business problems by taking constructive steps, predicting future trends, and making more-informed business decisions.
              </p>
            )}
            </div>
          </div>
        </div>
      )}
      {isMobileView && (
        <div className='container'>
        <div className='leftsercontent'>
          <TabView activeIndex={activeService - 1} onTabChange={(e) => handleServiceClick(e.index + 1)}>
            <TabPanel header="01">
            <p className="consult">Consultation services</p>
              <img className='seroimg' src={serimg1} alt='ser' />
              <p className='serpro'>
                We provide extensive industry experience and deep technical expertise and offer expert guidance and strategic insights to help businesses navigate the complex landscape of software development and digital transformation. The cost of our services is comparatively cheaper in the sector.
              </p>
            </TabPanel>
            <TabPanel header="02">
            <p className="consult">Enterprise software development</p>
              <img className='seroimg' src={serimg2} alt='ser' />
              <p className='serpro'>
              We offer exceptional enterprise software development solutions tailored to meet your organization's specific needs from requirement analysis to seamless integration, trust our expert team to deliver scalable and secure software solutions that drive productivity and propel your business forward.
              </p>
            </TabPanel>
            <TabPanel header="03">
            <p className="consult">Web & App development</p>
              <img className='seroimg' src={serimg3} alt='ser' />
              <p className='serpro'>
              We are committed to achieving outstanding outcomes for our clients by developing custom applications and enhancing existing software solutions. Our expertise is to deliver tailored solutions that optimize performance and meet unique business requirements in Android, iOS, and all possible platforms.
              </p>
            </TabPanel>
            <TabPanel header="04">
            <p className="consult">Web design</p>
              <img className='seroimg' src={serimg4} alt='ser' />
              <p className='serpro'>
              We specialize in designing UX & UI for a diverse range of web and mobile applications by integrating trends customized to meet clients' unique business goals and requirements.
              </p>
            </TabPanel>
            <TabPanel header="05">
            <p className="consult">Outsourcing Services</p>
              <img className='seroimg' src={serimg5} alt='ser' />
              <p className='serpro'>
              With an obsession for delivering value and best-in-class customer experience, we provide the best people, processes, and technology.
              </p>
            </TabPanel>
            <TabPanel header="06">
            <p className="consult">Data Mining</p>
              <img className='seroimg' src={serimg6} alt='ser' />
              <p className='serpro'>
              Our unique and specific data mining techniques will assist companies in addressing business problems by taking constructive steps, predicting future trends, and making more-informed business decisions.
              </p>
            </TabPanel>
          </TabView>
        </div>
        </div>
      )}
    </div>
  );
}
