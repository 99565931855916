import React from 'react';
import BlogBanner from '../BlogPage/BlogBanner/BlogBanner';
import Navbar from '../NavPage/Nav'
import BlogSection from './BlogSection/BlogSection';
import Header from '../Header/Header';

export default function App() {
  return (
    <>
      {/* <Navbar /> */}
      <Header/>
      <BlogBanner />
      <BlogSection />
    </>
  )
}

