import React, { useState, useEffect } from 'react';

import logo from '../Media/whitelogo.svg';

import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

import { AiOutlineRight, AiOutlineDown } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';

import './Nav.css';




const Header = () => {

  // const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);

  const [activeSubmenu, setActiveSubmenu] = useState('');




  // const handleMenuToggle = () => {                

  //   setIsMenuOpen(!isMenuOpen);

  // };




  useEffect(() => {

    const handleScroll = () => {

      const scrollHeight = window.pageYOffset;

      const scrollThreshold = 300; // Define the scroll threshold for changing the color

      setIsScrolled(scrollHeight > scrollThreshold);

    };




    window.addEventListener('scroll', handleScroll);




    return () => {

      window.removeEventListener('scroll', handleScroll);

    };

  }, []);




  const toggleSubmenu = (submenu) => {

    setActiveSubmenu(activeSubmenu === submenu ? '' : submenu);

  };




  return (

    <header>

      <div className={`nav-wrapper ${isScrolled ? 'solid' : ''}`}>

        <div className="logo-container">

        <a   href="/"

target={"_self"}><img className="navlogo" src={logo} alt="Logo" /></a>  

        </div>

        <nav>

          <input className="hidden" type="checkbox" id="menuToggle" />

          <label className="menu-btn" htmlFor="menuToggle">

            <div className="menu"></div>

            <div className="menu"></div>

            <div className="menu"></div>

          </label>

          <div className="nav-container">

            <ul className="nav-tabs">

              <li className="nav-tab">
          <NavLink to="/about" className='navi' activeclassname="active">
 About
</NavLink>
              </li>

              {/* <li className="dropdown">

                <button

                  className={`dropbtn ${activeSubmenu === 'product' ? 'active' : ''}`}

                  onClick={() => toggleSubmenu('product')}

                >

                <a href="/product" className='navi'>Product</a>

                  {activeSubmenu === 'product' ? (

                    <MdArrowDropUp className="reicon" />

                  ) : (

                    <MdArrowDropDown className="reicon" />

                  )}

                  {activeSubmenu === 'product' ? (

                    <AiOutlineDown className="downicon" />

                  ) : (

                    <AiOutlineRight className="downicon" />

                  )}

                </button>

                <div className={`dropdown-content ${activeSubmenu === 'product' ? 'show-submenu' : ''}`}>

                  <a href="">Exaan</a>

                  <a href="">Acaia</a>

                  <a href="">Av Scan</a>

                </div>

              </li> */}

<li className="nav-tab">

<NavLink to="/product" className='navi' activeclassname="active">
 Product
</NavLink>

</li>

              <li className="nav-tab">

              <NavLink to="/service" className='navi' activeclassname="active">
              Service
              </NavLink>

              </li>

              <li className="nav-tab">
                 <NavLink to="/blog" className='navi' activeclassname="active">
                 Insights
                  </NavLink></li>

              <li className="dropdown">

                <button

                  className={`dropbtn ${activeSubmenu === 'reachus' ? 'active' : ''}`}

                  onClick={() => toggleSubmenu('reachus')}

                >

                  Reach us

                  {activeSubmenu === 'reachus' ? (

                    <MdArrowDropUp className="reicon" />

                  ) : (

                    <MdArrowDropDown className="reicon" />

                  )}

                  {activeSubmenu === 'reachus' ? (

                    <AiOutlineDown className="downicon" />

                  ) : (

                    <AiOutlineRight className="downicon" />

                  )}

                </button>

                <div className={`dropdown-content ${activeSubmenu === 'reachus' ? 'show-submenu' : ''}`}>

                  <div className="arrow">

                    <a href="/careers">Careers</a>

                    <a href="/contactus">Contact us</a>

                    {/* <a href="">Gallery</a> */}

                  </div>

                </div>

              </li>

            </ul>

          </div>

        </nav>

      </div>

    </header>

  );

};




export default Header;