import React from 'react'
import Header from '../Header/Header';
import WhoWeAreBanner from './WhoWeAreBanner/WhoWeAreBanner';
import Ambitious from './Ambitious/Ambitious';
import WhatWeBelieve from './WhatWeBelieve/WhatWeBelieve';
import OurPresence from './OurPresence/OurPresence';

const WhoWeAre = () => {
    return (
        <>
            <Header />
            <WhoWeAreBanner />
            <Ambitious />
            <WhatWeBelieve />
            <OurPresence/>
        </>
    )
}

export default WhoWeAre;