import React from 'react';
import WhoWe from "./WhoWe";
import WhatWe from "./WhatWe";
import AboutBanner from "./AboutBanner";
import ScrollToTop from "../ScrollPage/Scrolltotop";
// import Mission from '../AboutPage/Mission';
import Navbar from '../NavPage/Nav';
import Header from '../Header/Header';
export default function About() {
    window.scrollTo(0,0);
    return (
        <div>
             {/* <Navbar /> */}
             <Header/>
            <ScrollToTop />
        
            <AboutBanner></AboutBanner>
            <WhoWe></WhoWe>
            <WhatWe></WhatWe>
            {/* <Mission></Mission> */}
        
           


     
        </div>
    )
}