import React, { Component } from "react";
import '../ReachUs/ContactSection.css';
import { eventTrack } from "../google/analytics";
import { ImLocation } from 'react-icons/im';
import { AiOutlineCheckCircle } from 'react-icons/ai'
// import GoogleMapReact from 'google-map-react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import Navbar from '../NavPage/Nav'
import Header from "../Header/Header";


const mapStyles = {
    width: '100%',
    height: '60vh'
};
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullname: "",
            email: "",
            contactno: "",
            org: "",
            msg: "",
            errorMessage: "",
            name: "",
            job: "",
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.clickEventHandler = this.clickEventHandler.bind(this);
    }

    handleChange(event) {
        switch (event.target.name) {
            case "fullname":
                this.setState({
                    fullname: event.target.value,
                });
                break;
            case "email":
                this.setState({
                    email: event.target.value,
                });
                break;
            case "contactno":
                this.setState({
                    contactno: event.target.value,
                });
                break;
            case "org":
                this.setState({
                    org: event.target.value,
                });
                break;
            case "msg":
                this.setState({
                    msg: event.target.value,
                });
                break;
            default:
                break;
        }
    }


    handleSubmit(event) {

        event.preventDefault();



        const fullName = this.state.fullname;

        const email = this.state.email;

        const contactno = this.state.contactno;

        const org = this.state.org;

        const msg = this.state.msg;

        const apiURL = process.env.REACT_APP_CONTACTUS_HOST;

        const apiPayload = {

            message: {

                form: "CONTACT_US_FORM",

                fullname: fullName,

                contactno: contactno,

                email: email,

                organisation: org,

                requirement: msg,


            },

        };

        if (email && contactno && org && msg && fullName) {

            fetch(apiURL, {

                method: "POST",

                headers: {

                    "Content-Type": "application/json",

                },

                body: JSON.stringify(apiPayload),

            })

                .then((response) => {

                    if (response.ok) {

                        setTimeout(() => {

                            this.setState({ showSuccessMessage: false });

                        }, 3000);

                        this.setState({

                            fullname: "",

                            email: "",

                            contactno: "",

                            org: "",

                            msg: "",

                            showSuccessMessage: true,

                        });

                    } else {

                        throw new Error("Network response was not ok");

                    }

                })

                .catch((error) => {

                    console.log(error);

                    alert("Sorry, there is a problem. Please try again later.");

                });

        } else {

            alert("Please enter the values.");

        }

    }


    maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }
    clickEventHandler(event) {
        eventTrack(
            event.target.innerText,
            event.type,
            event.view.navigator.appVersion
        );
    }

    render() {
        const { showSuccessMessage } = this.state;
        return (
            <>
                {/* <Navbar /> */}
                <Header />
                <div className="contactus">
                    <div className="contact-section">
                        <div className="contact-bg">
                            <div className="container">
                                <div className="contact-main">
                                    {/* <div className="contact-head">
                                        Let's Talk
                                    </div> */}


                                </div>
                            </div>
                        </div>
                    </div>

                    {/* form section */}
                    <div className="form-section">
                        <div className="container">
                            <div className="contact-form-heading">
                                <div className="contact-heading">
                                    Let's Talk!
                                </div>
                                {/* <div className='contact-dot-line'> <div className='contact-line'></div><div className='contact-dot-round'></div ></div> */}

                            </div>

                            <div className="form-sec">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-heading">Name</div>
                                    <div className="form-field">
                                        <input
                                            className="c-us-from-input"
                                            type={"text"}
                                            name={"fullname"}
                                            required={true}
                                            value={this.state.fullname}
                                            onChange={this.handleChange}
                                        ></input>
                                    </div>
                                    <div className="form-heading">Work Email</div>
                                    <div className="form-field">
                                        <input
                                            className="c-us-from-input"
                                            type={"email"}
                                            name={"email"}
                                            required={true}
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                        ></input>
                                    </div>
                                    <div className="form-heading">Phone</div>
                                    <div className="form-field">
                                        <input
                                            className="c-us-from-input"

                                            type="number"
                                            maxlength="10"
                                            onInput={this.maxLengthCheck}
                                            name={"contactno"}
                                            required={true}
                                            value={this.state.contactno}
                                            onChange={this.handleChange}
                                        ></input>
                                    </div>
                                    <div className="form-heading">Organization</div>
                                    <div className="form-field">
                                        <input
                                            className="c-us-from-input con-space"
                                            type={"text"}
                                            name={"org"}
                                            required={true}
                                            value={this.state.org}
                                            onChange={this.handleChange}
                                        ></input>
                                    </div>
                                    <div className="form-heading">Requirement</div>
                                    <div className="form-field">
                                        <input
                                            className="c-us-from-input con-space"
                                            type={"text"}
                                            name={"msg"}
                                            required={true}
                                            value={this.state.msg}
                                            onChange={this.handleChange}
                                        ></input>

                                    </div>

                                    <div className="form-button">
                                        <button className="contact-submit" onClick={this.handleSubmit}>Submit</button>
                                    </div>
                                    {showSuccessMessage && (

                                        <div className="success-message">

                                            <div className="success-message-content">

                                                <AiOutlineCheckCircle className='checkicon'></AiOutlineCheckCircle>

                                                <div className="success-message-heading">Successfully Submitted</div>





                                            </div>

                                        </div>

                                    )}
                                </form>
                            </div>
                            <div>

                            </div>


                        </div>

                    </div>

                    {/* map */}


                    <div className="map-section">
                        <div className="container">
                            <div className="map-content">
                                <div className="india-address">
                                    <div className="icon-sec" ><ImLocation className="address-icon"></ImLocation></div>

                                    <div className="in-add-sec">
                                        <h1>INDIA OFFICE</h1>
                                        <p>6/483 C,Fathima Road East,</p>
                                        <p>Azhagappapuram,</p>
                                        <p>Kanyakumari District,</p><p> Tamil Nadu - 629401.</p>
                                    </div>

                                </div>
                                <div className="usa-address">
                                    <div className="icon-sec" ><ImLocation className="address-icon"></ImLocation></div>
                                    <div className="in-add-sec">
                                        <h1>USA OFFICE</h1>
                                        <p>1200 ford rd, </p><p>Farmers Branch, </p>
                                        <p>Texas - 75234.</p>

                                    </div>
                                </div>
                            </div>
                            <div>

                            </div>
                        </div>
                    </div>

                    <div className="althi-map">

                        <Map
                            google={this.props.google}
                            zoom={14}
                            style={mapStyles}
                            initialCenter={
                                {
                                    lat: 8.147663782719468,
                                    lng: 77.5493067732016
                                }
                            }
                        >
                            <Marker key="marker_1"

                                position={{

                                    lat: 8.147663782719468,
                                    lng: 77.5493067732016

                                }}

                            />
                        </Map>

                    </div>


                </div>
            </>

        );
    }
}


export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY
})(Contact);
// export default Contact;