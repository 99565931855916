import ReactGA from "react-ga";

export const gaInit = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);
};

export const pageTrack = (action) => {
  // console.log(action);
  ReactGA.pageview(action);
};

export const eventTrack = (category, action, label) => {
  // console.log(category + " | " + action + " | " + label);
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};
