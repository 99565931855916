import React from 'react';
import "../AboutPage/About.css";
import Whowe from "../Media/whowe.png";

export default function AboutBanner() {
  return (
<div>
<div className='whowe-section'>

    <div className='container'>
<div className='whowe-heading'>
    <div className='whowe-left'>
    <img src={Whowe} alt='whowe' className='whowe'/>
           </div>
           <div className='whowe-right'>
         <div className='dot-line'> <div className='whowe-line'></div><div className='dot-round'></div ></div> 
    <div className='small-heading'>This is WHO We are</div>
    <div className='whowe-subhead'>Althi Solution delivers various <span className='green-subhead'>software</span> needs for a diverse <span  className='green-subhead'>global audience</span>.</div>
    <div className='whowe-content'> While other companies treat you as one of ​ the customers, we treat you as ​THE CUSTOMER, which distinguishes us from our competitors. We are trusted to ​create personalized software with the topmost security.</div>
    
           </div>


</div>


    </div>


</div>


</div>
  );
}