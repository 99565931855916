import React from 'react'
import Service from "../ServicePage/Service"
import Servicecontent from "../ServicePage/Servicecontent"
import Navbar from '../NavPage/Nav'
export default function servicerouter() {
  return (
    <div>
       <Navbar />
      <Service></Service>
      <Servicecontent></Servicecontent>
    </div>
  )
}
