import React, { useState, useEffect } from 'react';
import { FaAngleUp } from 'react-icons/fa';
// import { TbMessageCircle } from 'react-icons/tb';
import '../ScrollPage/Scrolltotop.css';
const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  // const [isPopupVisible, setIsPopupVisible] = useState(false);
  // const [messages, setMessages] = useState([]);
  

  // Show the button when the user scrolls down 300px
  const handleScroll = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Attach the scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Scroll to the top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // Show the popup when the message button is clicked
  // const showMessagePopup = () => {
  //   setIsPopupVisible(true);
  // };

  // Hide the popup
  // const hideMessagePopup = () => {
  //   setIsPopupVisible(false);
  // };
  //  const handleInputChange = (e) => {
  //   setInputValue(e.target.value);
  // };

  // const handleSendMessage = () => {
  //   if (inputValue.trim() !== '') {
  //     const newMessage = {
  //       id: Date.now(),
  //       text: inputValue,
  //     };
  //     setMessages((prevMessages) => [...prevMessages, newMessage]);
  //     setInputValue('');
  //   }
  // };
  // Render the button conditionally based on its visibility
  return (
    <div className="scroll">
      {/* <div className="message" >
        <TbMessageCircle onClick={showMessagePopup} />
      </div>
      {isPopupVisible && (
        <div className="popup">
          <div className="chatbox">
      <div className="chatbox-messages">
        {messages.map((message) => (
          <div key={message.id} className="chatbox-message">
            {message.text}
          </div>
        ))}
      </div>
      <div className="chatbox-input">
        <input
          type="text"
          placeholder="Type your message..."
          value={inputValue}
          onChange={handleInputChange}
        />
        <button onClick={handleSendMessage}>Send</button>
      </div>
    </div>
          <button className="close-button" onClick={hideMessagePopup}>
            Close
          </button>
        </div>
      )} */}
      <div
        className={`scroll-to-top ${isVisible ? 'visible' : ''}`}
        onClick={scrollToTop}
      >
        <FaAngleUp /> {/* Optional: You can use an icon library like FontAwesome */}
      </div>
    </div>
  );
};

export default ScrollToTopButton;
