
import React from 'react';
import "../AboutPage/About.css";
export default function AboutBanner() {
    window.scrollTo(0,0);
    return (


        <>

            <div className="main">
                <div className="bg">
                    {/* <div className="content">
                       <div className='radius'></div>
                        <div className='banner-head'>Integration of Technology with <span className='information'>Information</span> and <span className='innovation'>Innovation</span> </div>
                    </div> */}
                <div className='container'>  
                  <div className='aboutbanner-heading'>
                    
                        {/* <div className='green-round'></div> */}
                        <div className='banner-head'>Integration of Technology with <span className='information'>Information</span> and <span className='innovation'>Innovation</span></div>

                    </div>
                    </div>

                    {/* <div className="shape">
                        <div className="custom-shape-divider-bottom-1686050279">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                            </svg>
                        </div>
                        <div className="custom-shape-divider-bottom-1686050176">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                            </svg>
                        </div>
                    </div> */}
                </div>

            </div>

        </>
    );
}