import React from 'react'
import '../HomePage/Who.css'
import whoimage from "../Media/whoimage.png"
export default function Who() {
  return (
    <div>
        <div className="who-container">
        <div className='container'>
        <div className='who-wrapper'>
         
          
              <div className='star-line'>
            <div className="who-line"></div>
            <div className="dot"></div>
            </div>
            <div className='rapid-content'>
            <p className='who-content'>ALTHI is a rapidly <span className='content-word'>growing</span> company</p>
            </div>
            <div className='who-items'>
            <div className='leftwho-items'>
            <div className='design-para'>
              <p className='who-para'>that designs and develops software according to the clients requirements. We designed and developes a telematics solutions for a leading car manufacturer company in the USA, Canada and Mexico. We recently delivered a remittance software solutions project for a Top exchange house in Kuwait.</p>
            </div>
            </div>
            <div className='rightwho-items'>
            <img className='whoimages' src={whoimage} alt='whoi'></img>
            </div>
          </div>
          {/* <div className='grid'>
           <div className='grid-item'>
           <div className="who-line">
           <div className="dot"></div>
           <div className='rapid-content'>
                <p className='who-content'>ALTHI is a rapidly <span className='content-word'>growing</span> company</p>
              </div>
              <p className='who-para'>that designs and develops software according to the clients requirements.We designed and developes a telematics solutions for a leading car manufacturer company in the USA,Canada and Mexico.We recently delivered a remittance software solutions project for a Top exchange house in Kuwait.</p>

           </div>
           </div>
           <div className='grid-item'>
           <div className='who-circle'>
            <img className='whoimages' src={whoimage} alt='whoi'></img>
           </div>
           </div>
        </div> */}
        </div>
        </div>
    </div>
    </div>
  )
}
