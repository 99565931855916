import React from 'react';
import "../AboutPage/About.css";
import icons from "../Media/product.png";


export default function WhatWe() {
    return (
        <div>
            <div className='Whatwe-section'>
                <div className='container whatwe-p' >
                    <div className='whatwe-heading'>
                        <div className='whatwe-left'>
                            <div className='dot-line'> <div className='whatwe-line'></div><div className='dot-round'></div ></div>
                            <div className='whatwe-small-heading'>What we do</div>
                            <div className='whatwe-subhead'>Althi Solutions defines, creates, and provides <span className='green-subhead'>technology-enabled </span> </div>
                            <div className='whatwe-content'>business solutions for top companies with better availability, security, and quality. We are renowned for offering cutting-edge financial solutions that are safer and meet the highest quality standards.</div>

                        </div>
                        <div className='whatwe-right'>

                            <img className="what-icons" src={icons} alt="icons" />
                        </div>



                    </div>

                </div>
                {/* <svg width="762" height="1436" viewBox="0 0 762 1436" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_i_770_424)">
<path d="M49.7947 685.239L762 0V1436C647.373 1362.97 389.045 1199.72 188.176 1051.37C-42.5 881 -22.4998 771.5 49.7947 685.239Z" fill="white"/>
</g>
<defs>
<filter id="filter0_i_770_424" x="0.82959" y="0" width="761.171" height="1439" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood style={{ floodOpacity: 0 }} result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3"/>
<feGaussianBlur stdDeviation="5.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.562066 0 0 0 0 0.729167 0 0 0 0 0.689849 0 0 0 0.2 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow_770_424"/>
</filter>
</defs>
</svg> */}





                {/* <svg width="909" height="1703" viewBox="0 0 909 1703" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_306_1524)">
                        <path d="M55.4993 929.5L906.5 17L914.999 1680C776.666 1607 473.299 1433.9 222.499 1295.5C-28.3007 1157.1 6.66594 993.833 55.4993 929.5Z" fill="white" />
                    </g>
                    <defs>
                        <filter id="filter0_d_306_1524" x="0.75" y="0" width="934.249" height="1703" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                            <feOffset dy="3" />
                            <feGaussianBlur stdDeviation="10" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix type="matrix" values="0 0 0 0 0.562066 0 0 0 0 0.729167 0 0 0 0 0.689849 0 0 0 0.37 0" />
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_306_1524" />
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_306_1524" result="shape" />
                        </filter>
                    </defs>
                </svg> */}

            </div>
        </div>
    );
}