import React from 'react'
import Banner from "../HomePage/Banner"
import Who from "../HomePage/Who"
// import Industry from "../HomePage/Industry"
import ScrollToTop from "../ScrollPage/Scrolltotop";
import Founder from './Founder'
import Navbar from '../NavPage/Nav'
import Header from '../Header/Header';
export default function Main() {
  return (
    <div>
       {/* <Navbar /> */}
       <Header />
       <ScrollToTop />
     <Banner></Banner> 
      <Who></Who> 
       {/* <Industry></Industry>  */}
     <Founder></Founder>  
    </div>
  )
}