import React, { useState, useEffect } from 'react';
import founder1 from '../Media/founderimg.png';
import cofounder from '../Media/cofounder.png'
import { ImFacebook } from 'react-icons/im';
import { FaLinkedinIn } from 'react-icons/fa';
import { AiOutlineInstagram } from 'react-icons/ai';
import '../HomePage/Founder.css';

export default function Slider() {
  const [activeSlide, setActiveSlide] = useState(0);
  const slides = [
    {
      name: 'Wilson ',
      position: 'Founder',
      content: 'Wilson Raj, the founder of ALTHI SOLUTIONS, has 30+ years in business management and an impressive track record in running schools. Throughout his career, he has been known for his exceptional abilities transforming them into successful ventures.',
      image: founder1,
      socialLinks: {
        facebook: 'https://www.facebook.com/AlthiSolutions',
        linkedin: 'https://in.linkedin.com/company/althisolutions',
        instagram: 'https://www.instagram.com/althisolutions/'
      }
    },
    {
      name: 'Ashish ',
      position: 'Co-founder',
      content: 'Ashish Nadar, an Indian-American Business Executive with a career spanning over 13 years,  developed an ability to anticipate market trends and create software in finance and telematics. He is dedicated to sharing his knowledge and experiences to empower others to realize their own dreams.',
      image: cofounder,
      socialLinks: {
        facebook: 'https://www.facebook.com/AlthiSolutions',
        linkedin: 'https://in.linkedin.com/company/althisolutions',
        instagram: 'https://www.instagram.com/althisolutions/'
      }
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide(prevSlide => (prevSlide + 1) % slides.length);
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slider-wrapper">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`slider-slide ${activeSlide === index ? 'active' : ''}`}
        >
          <div className='container'>

            <div className='wholewrapper1'>

              {index % 2 === 0 ? (
                <>
                  <div className='leftfounder'>
                    <img className='foun-image' src={slide.image} alt={`Founder ${index + 1}`} />
                    <div className='social-icons'>
                      <div className='founder-icon'>
                        <div className='foundericon'>
                          <a
                            href={slide.socialLinks.facebook}
                            className='social-found'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <ImFacebook className='email-icon1' />
                          </a>
                        </div>
                        <div className='foundericon'>
                          <a
                            href={slide.socialLinks.twitter}
                            className='social-found'
                            target='_blank'
                            rel='noopener noreferrer'
                          ><FaLinkedinIn className='email-icon1' />
                           
                          </a>
                        </div>
                        <div className='foundericon'>
                          <a
                            href={slide.socialLinks.linkedin}
                            className='social-found'
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            <AiOutlineInstagram className='email-icon1' />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='rightfounder'>
                    <p className='foun-head'>
                      {slide.name} <span className='small-found'>Raj</span>
                    </p>
                    <p className='found-subhead'>{slide.position}</p>
                    <p className='found-content'>{slide.content}</p>
                  </div>
                </>

              ) : (
                <>
                  <div></div>
                  <div className='wholesection'>
                    <div className='rightfounders'>
                      <p className='foun-head'>
                        {slide.name} <span className='small-found'>Nadar</span>
                      </p>
                      <p className='found-subhead'>{slide.position}</p>
                      <p className='found-content'>{slide.content}</p>
                    </div>
                    <div className='leftfounder'>
                      <img className='foun-image' src={slide.image} alt={`Founder ${index + 1}`} />
                      <div className='social-icons'>
                        <div className='founder-icon'>
                          <div className='foundericon'>
                            <a
                              href={slide.socialLinks.facebook}
                              className='social-found'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <ImFacebook className='email-icon1' />
                            </a>
                          </div>
                          <div className='foundericon'>
                            <a
                              href={slide.socialLinks.twitter}
                              className='social-found'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                                 <FaLinkedinIn className='email-icon1' />
                            </a>
                          </div>
                          <div className='foundericon'>
                            <a
                              href={slide.socialLinks.linkedin}
                              className='social-found'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                               <AiOutlineInstagram className='email-icon1' />
                          
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}


            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
