import React from 'react';
import "../ServicePage/Service.css";



export default function Service() {
   window.scrollTo(0,0);
    return (
    <div className='service-wrapper'>
       <div className='service-bgimg'>
          <div className='service-screen'>
              <div className='service-main'>
                 <div className='container'>
                 <div className='servic-heading'>
                    
                    {/* <div className='servic-round'></div> */}
                    <div className='servic-head'>Solutions that work for you and <span className='servic-information'>services</span><br></br> that exceed your <span className='expectation'>expectations.</span></div>

                </div>
                {/* Solutions that work for you and services that exceed your expectations. */}
                 </div>
              </div>
           </div>
          </div>
       {/* <div className='servic-wave'>
           <svg width="100%" height="222" viewBox="0 0 1944 222" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1035.5 49.8777C623.5 142.018 173.833 57.1294 0.5 3.16788V203.764H1942V56.9161C1811.5 16.1784 1447.5 -42.2622 1035.5 49.8777Z" fill="#CFE1DE"/>
              <path d="M1002 75.792C582 167.676 159.667 100.853 1 55.9563V222H1944V55.9563C1779 23.9633 1527 -39.0629 1002 75.792Z" fill="white"/>
           </svg>
        </div> */}

    </div>
    );
}