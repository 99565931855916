import React from 'react'
import "../ProductPage/Product.css"

import acaialogo from "../Media/Acaialogo.png"
import exaan from "../Media/exaan.png"
import Navbar from '../NavPage/Nav'
import Header from '../Header/Header'
export default function product() {
  window.scrollTo(0,0);
  return (
    <>
     {/* <Navbar /> */}
      <Header />
       <div className='product-main'>
        <div className='product-image'>
            <div className='product-screen'>
             
               <div className='container'>
                <div className='productbanner-headin'>

                <div className='product-head'> <span className='New'>New</span>-Gen Inventions for </div>
                  <div className='product-head 1'> Next-level  <span className='Performance'>Performance</span></div>
                 

               </div>
               
            </div>
            {/* <div className="product-shape">
            <svg width="100%" height="312" viewBox="0 0 2200 312" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 129.998L1125.78 171.565L1710.16 216.586L2200 132.436V298.228L0 311.638V129.998Z" fill="white"/>
<path d="M2199.99 181.03V0C2029.66 106.194 1875.49 139.191 1821.22 153.198C1618.33 205.562 1322.69 196.987 1200.23 186.154C1022.39 162.718 796.326 144.043 705.525 137.635C480.091 121.156 296.751 153.808 233.782 172.422C464.227 138.001 731.617 170.134 835.985 190.274C961.227 212.703 1124.04 233.301 1186.66 241.54C1335.68 261.146 1562.73 265.952 1659.45 263.511C1916.61 260.581 2122.76 208.647 2199.99 181.03Z" fill="#37395C"/>
<path d="M0 248.877V63.3907C328.968 -9.84636 799.538 59.4202 1009.24 101.07C1463.24 185.751 1725.55 169.371 1824.35 151.825C1612.49 209.957 1295.36 204.464 1203.36 186.167C813.027 140.854 645.104 134.766 608.466 133.986C350.677 128.493 133.591 187.487 0 248.877Z" fill="#75A398"/>
</svg>


            </div> */}


        </div>
       </div>
       <div className='container'>
       <div className='producr-section'>
       
         <div className='acaia-img'><img className='acaialogo'src={acaialogo} alt='acaia'></img> </div>
         <div className='product-form'>
       <div className="prosection-heading">The Absolute AML Software</div>
      
       <div className='product-line'><div className='product-round'></div ></div>
     
       </div>
       <div className='product-content'> An Anti-Money Laundering tool that assists businesses all over the world in meeting their regulatory requirements. It helps you stay ahead of regulations with effortless compliance management. Guides financial organizations to be fully compliant by identifying financial crime and preventing AML/CTF & fraud. </div>
       <div className='product-comingsoon'><div className='bord'></div><div className='product-com-con'>A Revolutionary Software, Is Coming Soon To Shape The Future Of The Compliance World.
</div></div>
       <img className='exaan'src={exaan} alt='exaan'></img>
       <div className='product-form'>
       <div className="prosection-heading">EXAAN-New Gen Money Exchange</div>
       <div className='product-line'><div className='product-round'></div ></div>
       </div>
      
       <div className='product-content'> The all-in-one financial management solution with real-time currency conversion rates. It supports multi-currency transactions within a single platform. It contains robust compliance features that maintain legal and regulatory requirements, including Know Your Customer (KYC) procedures and anti-money laundering (AML) checks  </div>

       <div className='product-comingsoon'><div className='bord'></div><div className='product-com-con'>Reach you soon with advanced Currency Conversion technology.
</div></div>
     
      </div>
      </div>
   </div></>
  )
}
